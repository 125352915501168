import React, { useState, Component, useEffect } from 'react';
import $ from 'jquery';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TextField,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  TablePagination
} from '@material-ui/core';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { getInitials } from 'helpers';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));



const handleEditarUtilizador = (dossiers) => {


}

const editarUtilizador = () => {

}



const handleEliminarUtilizador = (dossiers) => {

  //verify checked checkbox to delete user
  dossiers.forEach(dossier => {
    console.log(document.getElementById("user-" + dossier.username))
    if (document.getElementById('user-' + dossier.username)) {
      if (document.getElementById("user-" + dossier.username).checked) {



        confirmAlert({
          title: 'Eliminar',
          message: 'Pretende Eliminar Utilizador ' + dossier.username + ' ?',
          buttons: [
            {
              label: 'Sim',
              onClick: () => {
                var formData = new FormData();
                formData.append('username', dossier.username);
        
                $.ajax({
                  type: "POST",
                  url: "https://dossieradmin.maxiglobal.net/api/eliminarUser.php",
                  processData: false,
                  data: formData,
                  contentType: false,
                  success: function (resultado) {
        
       
                  }
                });
              }
            },
            {
              label: 'Não',
              onClick: () => {}
            }
          ]
        });
     



      }
    }
  });

}
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);



var carregado = false;
const UsersTable = props => {
  const { className, users, ...rest } = props;

  const classes = useStyles();
  const [values, setValues] = useState({
    Dossiers: [],

  });
  const [dossiers, setValueDossiers] = useState([]);
  const setDossiers = (newDossiers) => {
    setValueDossiers(newDossiers);
  }
    //STATUS PARA EDIÇÔES!
    const [ModalOpenEdit, ModalsetOpenEdit] = React.useState(false);

    //Open Modal
    const ModalHandleOpenEdit = () => {
      ModalsetOpenEdit(true);
    };
    //Close Modal
    const ModalHandleCloseEdit = () => {
      ModalsetOpenEdit(false);
    };

    const [estadoUserEditing, setUserEditing] = React.useState(0);

    //Open Modal
    const ModalSetUserEditing = (value) => {
      setUserEditing(value);
    };

  
    const [userNameEditing, setUserNameEditing] = React.useState('');

    //Open Modal
    const ModalSetUserNameEditing = (value) => {
      setUserNameEditing(value);
    };


    //ESTADOS PARA CREATE NEW USERS
        //Click DS Dialog
        const [ModalOpenNewUser, ModalSetOpenCreate] = React.useState(false);

        //Open Modal
        const ModalHandleOpenCreate = () => {
          ModalSetOpenCreate(true);
        };
        //Close Modal
        const ModalHandleCloseCreate = () => {
          ModalSetOpenCreate(false);
        };

        const [passwordNewUser, serPasswordNewuser] = React.useState('');

        //Open Modal
        const ModalSetPasswordNewUser = (e) => {
          serPasswordNewuser(e.target.value);
        };
    
      
        const [usernameNewUser, setUserNameNewUser] = React.useState('');
    
        //Open Modal
        const ModalSetUsernameNewUser = (e) => {
          setUserNameNewUser(e.target.value);
        };

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);




  var path = "https://dossieradmin.maxiglobal.net/api"
  $.ajax({
    type: "GET",
    url: path + "/listaUsers.php",
    datatype: 'json',
    ContentType: "application/json",
    complete: function (data) {
      setDossiers(JSON.parse(data.responseText))


    }

  });


  const handleEditUtilizadorModal = (dossiers) => {

    var gotUserToEdit=0; 
    dossiers.forEach(dossier => {
      console.log(document.getElementById("user-" + dossier.username))
      if (document.getElementById('user-' + dossier.username)) {
        if (document.getElementById("user-" + dossier.username).checked) {
          //set estado de user
          var estadouser = dossier.estado;
          ModalSetUserEditing(estadouser); 
          ModalSetUserNameEditing(dossier.username); 
          ModalHandleOpenEdit(); 
          gotUserToEdit++; 
        }
      }
    });
    if (gotUserToEdit==0) {
      alert("Por favor selecionar Utilizador a Editar!"); 
    }
  };

  const ActualCreateUserStatus = () => {
    console.log("create user"); 
    var formData = new FormData();
    formData.append('emailRegisto', usernameNewUser);
    formData.append('passwordRegisto', passwordNewUser);

    $.ajax({
      type: "POST",
      url: "https://dossieradmin.maxiglobal.net/api/criarUtilizador.php",
      processData: false,
      data: formData,
      contentType: false,
      success: function (resultado) {
      console.log(resultado); 
      if (resultado=="existe") {
        alert("Utilizador já existe!");
      } else {
        ModalHandleCloseCreate(); 

      }

      }
    });
  }
  const ActualEditUserStatus = () => {
  

    var formData = new FormData();
    formData.append('username', userNameEditing);
    formData.append('estado', estadoUserEditing);

    $.ajax({
      type: "POST",
      url: "https://dossieradmin.maxiglobal.net/api/changeUserStatus.php",
      processData: false,
      data: formData,
      contentType: false,
      success: function (resultado) {
        alert("Estado Atualizado!"); 

        ModalHandleCloseEdit(); 
      }
    });
    //changeUserStatus
  }


  const handleChangeEstadoUser = (event) => {
    ModalSetUserEditing(event.target.value); 
  }


  return (

    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <span className={classes.spacer} />
      <Button
        onClick={() => {  handleEditUtilizadorModal(dossiers) }}

        className={classes.importButton}>Editar </Button>
      <Button
        onClick={() => { handleEliminarUtilizador(dossiers) }}

        className={classes.importButton}>Eliminar Utilizador</Button>
      <Button
        onClick={() => {ModalHandleOpenCreate()}}
        color="primary"
        variant="contained"
      >
        Registo Utilizador
        </Button>
      <CardContent className={classes.content}>
      <Dialog
              fullWidth="500"
              disableBackdropClick
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={ModalOpenEdit}
              onClose={ModalHandleCloseEdit}>
                      <DialogTitle id="tipoDS">Estado Utilizador: {userNameEditing}</DialogTitle>

              <DialogContent dividers>
                <CardContent>
                  
                <Select
                value={estadoUserEditing}
                onChange={handleChangeEstadoUser}
                id="estadoUserEditing"
              
        >
           <MenuItem value={0}>Inativo</MenuItem>
          <MenuItem value={1}>Ativo</MenuItem>
        </Select>
               
                </CardContent>
                          </DialogContent>
              <DialogActions>
              <Button onClick={() => {ModalHandleCloseEdit()}} color="primary">
                  Cancelar
                  
          </Button>
                <Button onClick={() => {ActualEditUserStatus()}} color="primary">
                  Editar
                  
          </Button>
         
              </DialogActions>
            </Dialog>

            <Dialog
              fullWidth="500"
              disableBackdropClick
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={ModalOpenNewUser}
              onClose={ModalHandleCloseCreate}>
                      <DialogTitle id="tipoDS">Registo Utilizador</DialogTitle>

              <DialogContent dividers>
                <CardContent>
                <TextField
                    fullWidth
                    value={usernameNewUser}
                    onChange={ModalSetUsernameNewUser}
                    id="username"
                    label="Username"
                    name="username"
                    type="text"
                    variant="outlined" />
      </CardContent>
      <CardContent>
      <TextField
                    fullWidth
                    value={passwordNewUser}
                    onChange={ModalSetPasswordNewUser}
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    variant="outlined" />
        </CardContent>
   
               
        
                          </DialogContent>
              <DialogActions>
              <Button onClick={() => {ModalHandleCloseCreate()}} color="primary">
                  Cancelar
                  
          </Button>
                <Button onClick={() => {ActualCreateUserStatus()}} color="primary">
                  Registar
                  
          </Button>
         
              </DialogActions>
            </Dialog>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>

                  <TableCell>Username</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell padding="checkbox">

                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {dossiers.map(dossier => (
                  <TableRow
                    key={dossier.username}
                  >

                    <TableCell>{dossier.username}</TableCell>
                    {dossier.estado == 1 ?
                      <TableCell>Ativo</TableCell> :
                      <TableCell>Inativo</TableCell>}


                    <TableCell>
                      <Checkbox
                        id={'user-' + dossier.username}
                        color="primary"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>

    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
